<template>
  <v-dialog
    v-model="dialog"
    persistent
    :min-width="xs ? 0 : 500"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{ $t('tmpAppointmentDialog.titleAppointmentCanceled') }}</span>
        <v-btn
          class="close-icon mr-2"
          variant="text"
          icon="mdi-close"
          @click="handleConfirm"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pa-4 text-center">
        {{ $t('tmpAppointmentDialog.messageAppointmentCanceled') }}
      </v-card-text>
      <v-card-actions class="d-flex justify-center align-center pa-4 pt-2">
        <v-btn
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :text="$t('confirmDialog.confirmBtn')"
          @click="handleConfirm"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useDisplay } from 'vuetify/lib/framework.mjs'
const { xs } = useDisplay()
const dialog = defineModel()
const handleConfirm = () => {
  dialog.value = false
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
}
</style>
