<template>
  <v-dialog
    :model-value="dialogAvis"
    @update:model-value="updateDialogAvis"
    persistent
    max-width="600"
    transition="dialog-top-transition"
    class="blur-background"
  >
    <v-card elevation="24" class="border-radius-none">
      <v-card-title
        class="d-flex pa-4 justify-space-between dialog-header align-center"
      >
        <span class="text-wrap text-left">{{
          $t('dashboard.avisConsultationDialog.title')
        }}</span>
        <v-btn icon variant="text" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="h-overflow">
          <v-list>
            <v-list-item
              v-for="consultation in dialogAvisConsultationStore.consultations"
              :key="consultation.id"
              class="mb-4 border-list"
            >
              <v-card class="pa-4" outlined>
                <v-row no-gutters>
                  <v-col
                    cols="2"
                    class="d-flex justify-center align-self-start"
                  >
                    <v-avatar size="80" class="ml-10">
                      <v-img :src="consultation.photo"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10">
                    <div class="d-flex flex-wrap align-items-center mb-2">
                      <div>
                        <strong
                          >{{
                            $t('dashboard.avisConsultationDialog.fullName')
                          }}
                          :</strong
                        >
                        {{ consultation.practitionerName }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap align-items-center mb-2">
                      <div>
                        <strong
                          >{{
                            $t('dashboard.avisConsultationDialog.reason')
                          }}
                          :</strong
                        >
                        {{ consultation.appointmentReason }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="flex-grow-1 mr-4">
                        <strong
                          >{{
                            $t('dashboard.avisConsultationDialog.duration')
                          }}
                          :</strong
                        >
                        {{ consultation.duration }}
                      </div>
                      <div class="flex-grow-1">
                        <strong
                          >{{
                            $t('dashboard.avisConsultationDialog.price')
                          }}
                          :</strong
                        >
                        {{ consultation.price }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mb-2">
                      <div class="flex-grow-1 mr-4">
                        <strong
                          >{{
                            $t('dashboard.avisConsultationDialog.date')
                          }}
                          :</strong
                        >
                        {{ consultation.date }}
                      </div>
                      <div class="flex-grow-1">
                        <strong
                          >{{
                            $t('dashboard.avisConsultationDialog.time')
                          }}
                          :</strong
                        >
                        {{ consultation.time }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="consultation.comment"
                      :label="$t('dashboard.avisConsultationDialog.note')"
                      outlined
                      class="w-full mt-2 custom-textarea"
                      rows="2"
                      variant="filled"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="mt-4 text-center">
                      <strong>{{
                        $t('dashboard.avisConsultationDialog.confirm')
                      }}</strong>
                      <div class="d-flex justify-center mt-2">
                        <v-btn
                          icon
                          color="green"
                          class="mx-2 text-white"
                          @click="
                            () =>
                              sendReview(
                                consultation.id,
                                consultation.comment,
                                true,
                              )
                          "
                        >
                          <v-icon>mdi-thumb-up</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="red"
                          class="mx-2 text-white"
                          @click="
                            () =>
                              sendReview(
                                consultation.id,
                                consultation.comment,
                                false,
                              )
                          "
                        >
                          <v-icon>mdi-thumb-down</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end dialog-actions">
        <span class="dialog-text">
          {{ $t('dashboard.avisConsultationDialog.modifyChoiceInfo') }}
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import LoadingBackdropService from '../feedback/loadingBackdrop/loadingBackdropService'
import createHttpClient from '@/api/httpClient'
import MessageService from '../feedback/message/messageService'
import { useDialogAvisConsultationStore } from '@/store/dialogAvisConsultationStore/dialogAvisConsultationStore'
import { useUserStore } from '@/store/user/userStore'

export default {
  props: {
    dialogAvis: Boolean,
  },
  emits: ['update:dialogAvis', 'close'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const httpClient = createHttpClient()
    const dialogAvisConsultationStore = useDialogAvisConsultationStore()

    const consultations = ref([
      // {
      //   id: 1,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
    ])

    const updateDialogAvis = value => {
      emit('update:dialogAvis', value)
    }

    const toggleDialog = () => {
      updateDialogAvis(false)
    }

    const validate = () => {
      updateDialogAvis(false)
    }

    const sendReview = async (id, opinion, thumbUp: boolean) => {
      LoadingBackdropService.start()
      try {
        // Handle consultation confirmation
        await httpClient.post(
          `/opinion/opinion/${id}`,
          { opinion, thumbUp },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }

      const userStore = useUserStore()
      // if not connected, do not fetch
      if (!userStore.user.token) {
        LoadingBackdropService.stop()
        return
      }

      await dialogAvisConsultationStore.fetchConsultations()

      LoadingBackdropService.stop()
    }

    return {
      t,
      consultations,
      sendReview,
      dialogAvisConsultationStore,
      updateDialogAvis,
      toggleDialog,
      validate,
    }
  },
}
</script>

<style scoped>
.v-card-text .v-btn {
  min-width: 70px;
  border-radius: 60px !important;
}

.btn--save {
  min-width: 150px;
  background-color: #1a73e9;
}

.text-white {
  color: #fff !important;
}

.border-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
}

.v-card-text,
.v-list-item,
.v-card {
  background-color: #fff !important;
}

.v-list {
  background: none !important;
}

.dialog-header {
  background-color: #fef4eb;
  color: #f09954;
  text-align: center;
}

.h-overflow {
  max-height: 550px;
  overflow-y: auto;
}

.ml-10 {
  margin-left: -10px !important;
}

.align-self-start {
  align-self: flex-start;
}

.dialog-actions {
  background-color: white;
}

.dialog-text {
  font-size: 13px;
  color: gray;
  margin-left: 16px;
  margin-right: 16px;
}

.btn--save {
  background-color: #1a73e9;
  color: white;
}

.custom-textarea .v-input__control {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
}

.blur-background {
  backdrop-filter: blur(10px);
  box-shadow: 10px 10px 10px #00000072;
  &:deep(.v-overlay__scrim) {
    background-color: #fff;
  }
}

.border-radius-none {
  border-radius: 0 !important;
}
</style>
