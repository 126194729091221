<template>
  <v-dialog
    v-model="dialog"
    persistent
    :min-width="!xs ? 500 : 300"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 text-wrap w-full align-center bg-surface-lighten-1 text-primary"
        :class="[xs ? 'justify-space-between' : 'justify-center']"
      >
        <span>{{ title }}</span>
        <v-btn
          v-if="!mandatory"
          class="mr-2"
          :class="{ 'close-icon': !xs }"
          variant="text"
          icon="mdi-close"
          @click="handleCancel"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pa-4 text-center">
        {{ message }}
      </v-card-text>
      <v-card-actions
        class="d-flex justify-end align-center flex-wrap pa-4 pt-2"
      >
        <v-btn
          v-if="!onlyConfirmAction"
          class="px-6"
          variant="outlined"
          color="black"
          :text="cancelBtnLabel ?? $t('confirmDialog.cancelBtn')"
          @click="handleCancel"
        />
        <v-btn
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :text="confirmBtnLabel ?? $t('confirmDialog.confirmBtn')"
          @click="handleConfirm"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- <v-dialog
    v-model="dialog"
    persistent
    width="500"
    transition="dialog-top-transition"
  >
    <v-card :title="title">
      <v-divider></v-divider>
      <v-card-text>{{ message }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="outlined"
          :text="cancelBtnLabel ?? $t('confirmDialog.cancelBtn')"
          @click="handleCancel"
        >
        </v-btn>
        <v-btn
          color="secondary"
          variant="outlined"
          :text="confirmBtnLabel ?? $t('confirmDialog.confirmBtn')"
          @click="handleConfirm"
        >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> -->
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, reactive } from 'vue'
import EventBus from '@/utils/eventBus'
import { useDisplay } from 'vuetify'

const { xs } = useDisplay()
const dialog = ref(false)
const title = ref('')
const message = ref('')
const options = reactive({
  onConfirm: () => {},
  onCancel: () => {},
})
const cancelBtnLabel = ref<string>()
const confirmBtnLabel = ref<string>()

const mandatory = ref(false)
const onlyConfirmAction = ref(false)

onMounted(() => {
  // @ts-ignore
  const handleConfirm = (confirmParams: IConfirmParams) => {
    title.value = confirmParams.title
    message.value = confirmParams.message
    options.onConfirm = confirmParams.optionsConfirm.onConfirm
    options.onCancel = confirmParams.optionsConfirm.onCancel
    cancelBtnLabel.value = confirmParams.cancelBtnLabel
    confirmBtnLabel.value = confirmParams.confirmBtnLabel
    dialog.value = true
    mandatory.value = confirmParams.mandatory
    onlyConfirmAction.value = confirmParams.onlyConfirmAction
  }

  EventBus.on('confirm', handleConfirm)
  EventBus.on('close-confirm', close)
})
const close = () => {
  dialog.value = false
}

onBeforeUnmount(() => {
  EventBus.off('confirm', handleConfirm)
  EventBus.off('close-confirm', close)
})

const handleConfirm = () => {
  options.onConfirm()
  dialog.value = false
}

const handleCancel = () => {
  options.onCancel()
  dialog.value = false
}
</script>
<style lang="scss" scoped>
.close-icon {
  position: absolute;
  right: 0;
}
@media screen and (max-width: 380px) {
  .v-card-title > span {
    font-size: 14px;
    position: relative;
    left: 0;
  }
}
</style>
